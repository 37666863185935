import React from "react";
import Navigate from "../navigate";
import cx from "classnames";

function CategoryTitleBordered(props) {
    const className = cx(
        "top-border-category relative font-montserrat font-bold text-white lg:text-denim text-2xl lg:pl-4 flex items-center break-word",
        props.className,
        props.className?.includes("mb") || props.className?.includes("my") ? '' : 'mb-3',
        props.className?.includes("mt") || props.className?.includes("my") ? '' : 'mt-3',
    );

    const titleClass = cx(
      "flex-shrink-0 p-4 lg:p-0 uppercase",
      props.titleClass,
      props.titleClass?.includes("bg-") ? '' : 'bg-denim lg:bg-transparent',
      props.titleClass?.includes("pl-") ? '' : 'pl-4'
    );

  const borderClass = cx(
    "bg-denim w-full lg:ml-4",
    props.borderClass
    );

    const render = () => {
        return <div className={className} style={{minHeight: "60px"}} data-datocms-noindex>
          <div className={titleClass}>{props.title}</div>
          <div className={borderClass} style={{height: "0.5px"}}></div>
        </div>;
    }

  const breadCrumb = () => {
    return <div className={className} style={{minHeight: "60px"}} data-datocms-noindex>
      <div className={titleClass}>
        <Navigate href={props.parentLink} className={`text-base lg:text-2xl`}>
          {props.parentTitle}
        </Navigate>
        <span className={"category-breadcrumb"}/>
        <Navigate href={props.link} className={`text-base lg:text-2xl`}>
          {props.title}
        </Navigate>
      </div>

      <div className={borderClass} style={{height: "0.5px"}}></div>
    </div>
  }

  const navigation = () => {
    if (props.parentTitle) {
      return breadCrumb();
    }
    return <Navigate href={props.link}>
      {render()}
    </Navigate>;
  }

    return <>
      {props.link
        ? navigation()
        : render()
      }
    </>;
}

export default CategoryTitleBordered;
